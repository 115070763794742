import React from 'react'
import { db, auth } from '../firebase-config'
import { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { Row } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import Header from './Header'
import moment from 'moment'
import { collection, doc, getDoc, updateDoc, Timestamp } from "firebase/firestore"
import { UserAuth } from '../context/AuthContext'
import { useNavigate, useParams } from 'react-router-dom'
import imgCancel from '../images/cancel.png'
import imgSave from '../images/save.png'

const UpdateVehicle = () => {

    const [ vehicle, setVehicle ] = useState("");
    const { vehicleid } =  useParams();
    const singleVehicleCollectionRef = collection(db, "vehicleinfo")
    const navigate = useNavigate();
    const { user } = UserAuth()

    useEffect(()  => {
    
      const loadVehicle = async () => {
        const vehicleRef = doc(singleVehicleCollectionRef, vehicleid)
        const unsub = await getDoc(vehicleRef)
            .then((doc) => {
              setVehicle(doc.data())
              document.getElementById("newYear").value = doc.data().year
              document.getElementById("newMake").value = doc.data().make
              document.getElementById("newModel").value = doc.data().model
              document.getElementById("newColor").value = doc.data().color
              document.getElementById("newEngine").value = doc.data().engine
              document.getElementById("newTransmission").value = doc.data().transmission
              document.getElementById("newTireSizeFront").value = doc.data().tiresizefront
              document.getElementById("newTireSizeRear").value = doc.data().tiresizerear
              document.getElementById("newPurchaseDate").value = moment(doc.data().purchasedate.toDate()).format("YYYY-MM-DD 00:00:00")
              document.getElementById("newPurchaseLocation").value = doc.data().purchaselocation
              document.getElementById("newPurchaseMileage").value = doc.data().purchasemileage
              document.getElementById("newPurchaseAmount").value = doc.data().purchaseamount
              document.getElementById("newActive").value = doc.data().active
            })
    
            return () => {
            unsub();
            }
        }

        loadVehicle();


    }, [])


    const updateVehicle = async (event) => {
      event.preventDefault();

      var newYear = document.getElementById("newYear").value
      var newMake = document.getElementById("newMake").value
      var newModel = document.getElementById("newModel").value
      var newColor = document.getElementById("newColor").value
      var newEngine = document.getElementById("newEngine").value
      var newTransmission = document.getElementById("newTransmission").value
      var newTireSizeFront = document.getElementById("newTireSizeFront").value
      var newTireSizeRear = document.getElementById("newTireSizeRear").value
      var newPurchaseDate = document.getElementById("newPurchaseDate").value
      var newPurchaseLocation = document.getElementById("newPurchaseLocation").value
      var newPurchaseMileage = document.getElementById("newPurchaseMileage").value
      var newPurchaseAmount = document.getElementById("newPurchaseAmount").value
      var newActive = document.getElementById("newActive").value
      
      const updatedChanges = {
        year: newYear,
        make: newMake,
        model: newModel,
        color: newColor,
        engine: newEngine,
        transmission: newTransmission,
        tiresizefront: newTireSizeFront,
        tiresizerear: newTireSizeRear,
        purchasedate: Timestamp.fromDate(new Date(newPurchaseDate)),
        purchaselocation: newPurchaseLocation,
        purchasemileage: newPurchaseMileage,
        purchaseamount: newPurchaseAmount,
        active: newActive
      }
      
      const vehicleRef = doc(singleVehicleCollectionRef, vehicleid)
      await updateDoc(vehicleRef, updatedChanges)
      navigate("/vehicles")
    }

  
    return (
    <div className='m-1'>
      <Header />

      <Container className='panel col-sm-12 col-md-8 xcol-lg-6 mx-auto'>

          <Row>
            <Col>
              <h1>Update Vehicle</h1>
              <hr />
              
              <h2 className='m-0'>{vehicle.year} {vehicle.make} {vehicle.model}</h2>
              <p className='m-0 mb-3' style={{fontSize: ".75rem"}}>vID: {vehicle.vID}</p>
              <div className='d-flex singleVehicleLayout'>
                <div className='svlImg'>
                  <img className='' style={{width: "400px", height: "300px"}} src={vehicle.picID} alt='vehicle' />
                </div>
                <div className='vehicleDetails'>
                <form>
                  <p className='m-0'><span>Year:</span></p>
                  <p><input className='p-1' id='newYear' /></p>
                  
                  <p className='m-0'><span>Make:</span></p>
                  <p><input className='p-1' id='newMake' /></p>
                  
                  <p className='m-0'><span>Model:</span></p>
                  <p><input className='p-1' id='newModel' /></p>

                  <p className='m-0'><span>Color</span></p>
                  <p><input className='p-1' id='newColor' /></p>
                  
                  <p className='m-0'><span>Engine:</span></p>
                  <p><input className='p-1' id='newEngine' /></p>
                  
                  <p className='m-0'><span>Transmission:</span></p>
                  <p><input className='p-1' id='newTransmission'  /></p>

                  <p className='m-0'><span>Tire Size Front:</span></p>
                  <p><input className='p-1' id='newTireSizeFront'  /></p>

                  <p className='m-0'><span>Tire Size Rear:</span></p>
                  <p><input className='p-1' id='newTireSizeRear'  /></p>
                  
                  <p className='m-0'><span>Purchase Date:</span></p>
                  <p><input className='p-1' id='newPurchaseDate' /></p>
                  
                  <p className='m-0'><span>Purchase Location:</span></p>
                  <p><input className='p-1' id='newPurchaseLocation'  /></p>
                  
                  <p className='m-0'><span>Purchase Mileage:</span></p>
                  <p><input className='p-1' id='newPurchaseMileage'  /></p>
                  
                  <p className='m-0'><span>Purchase Amount:</span></p>
                  <p><input className='p-1' id='newPurchaseAmount'  /></p>
                  
                  <p className='m-0'><span>Active:</span></p>
                  <p><input className='p-1' id='newActive' /></p>
                </form>
                </div>
              </div>
            </Col>
            <div className='d-flex justify-content-end mt-3'>
              <a onClick={() => navigate(-1)}><img className="icons" src={imgCancel} /></a>
              <a onClick={updateVehicle}><img className="icons ms-3" src={imgSave} /></a> 
            </div>
          </Row>

      </Container>
    
    </div>
  )
}

export default UpdateVehicle