import React from 'react';
import { useState, useEffect } from "react"; 
import { collection, addDoc, Timestamp } from "firebase/firestore";
import moment from 'moment';
import Header from './Header';
import { db, auth } from "../firebase-config";
import { useNavigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext'
import imgCancel from '../images/cancel.png'
import imgSave from '../images/save.png'
import { v4 as uuid } from "uuid";
import { storage } from '../firebase-config';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"


const AddVehicle = () => {
    const [year, setYear] = useState("");
    const [make, setMake] = useState("");
    const [model, setModel] = useState("");
    const [color, setColor] = useState("");
    const [engine, setEngine] = useState("");
    const [transmission, setTransmission] = useState("");
    const [tiresizefront, setTireSizeFront] = useState("");
    const [tiresizerear, setTireSizeRear] = useState("");
    const [purchasedate, setPurchaseDate] = useState("");
    const [purchaselocation, setPurchaseLocation] = useState("");
    const [purchasemileage, setPurchaseMileage] = useState("");
    const [purchaseamount, setPurchaseAmount] = useState("");
    // const [imgID, setImgID] = useState("/images/placeholder_logo.jpg");
    const [vID, setVID] = useState("");
    const [imageUpload, setImageUpload] = useState(null);
    const [picID, setPicID] = useState(null);

    const { user } = UserAuth()
    const vehicleCollRef = collection(db, "vehicleinfo");

    const navigate = useNavigate();

    
    
    useEffect(()  => {
    
        // const vID = uuid();
        setVID(uuid())
        // console.log(vID)
    
    }, [])



    const uploadImage = async () => {

        if (imageUpload == null) return;

        const imageRef =  ref(storage, `/${user.email + imageUpload.name + vID}`)
        
        await uploadBytes(imageRef, imageUpload).then(data => {
            alert("Image uploaded successfully")
            console.log("data, imageRef below")
            console.log(data, imageRef)
            getDownloadURL(data?.ref).then(val => {
                addVehicle(val)
            })
            
        })

    }


   
    // const addVehicle = async (event) => {
    const addVehicle = async (picID) => {
        // event.preventDefault()
                
        const newVehicle = {
            useremail: user.email,
            year: year,
            make: make,
            model: model,
            color: color,
            engine: engine,
            transmission: transmission,
            tiresizefront: tiresizefront,
            tiresizerear: tiresizerear,
            purchasedate: Timestamp.fromDate(new Date(purchasedate)),
            purchaselocation: purchaselocation,
            purchasemileage: purchasemileage,
            purchaseamount: purchaseamount,
            vID: vID,
            picID: picID,
            active: "Y"
        }
    
        await addDoc(vehicleCollRef, newVehicle)
        navigate("/vehicles")
        
        // clearFields();
      
    }

   

    return (
        <div>
        
            <Header />
            <div className="m-1">
                <div className="panel addVehicle col-sm-12 col-md-8 col-lg-6 mx-auto"> {/* start of add vehicle panel */}
                    <h1>Add Vehicle</h1>
                    <hr />
                    <form className='d-flex flex-column'>
                    <input className='m-1 p-2' type="text" id="year" placeholder="Year"  required onChange={(event) => {setYear(event.target.value)}} />
                    <input className='m-1 p-2' type="text" id="make" placeholder="Make"  required onChange={(event) => {setMake(event.target.value)}} />
                    <input className='m-1 p-2' type="text" id="model" placeholder="Model"  required onChange={(event) => {setModel(event.target.value)}} />
                    <input className='m-1 p-2' type="text" id="color" placeholder="Color"  onChange={(event) => {setColor(event.target.value)}} />
                    <input className='m-1 p-2' type="text" id="engine" placeholder="Engine" onChange={(event) => {setEngine(event.target.value)}} />
                    <input className='m-1 p-2' type="text" id="transmission" placeholder="Transmision" onChange={(event) => {setTransmission(event.target.value)}} />
                    <input className='m-1 p-2' type="text" id="tiresizefront" placeholder="Tire Size Front" onChange={(event) => {setTireSizeFront(event.target.value)}} />
                    <input className='m-1 p-2' type="text" id="tiresizerear" placeholder="Tire Size Rear" onChange={(event) => {setTireSizeRear(event.target.value)}} />
                    <input className='m-1 p-2' type="text" id="purchasedate" placeholder="Purchase Date 01/01/2000 13:00:00" onChange={(event) => {moment(setPurchaseDate(event.target.value)).toDate()}} />
                    <input className='m-1 p-2' type="text" id="purchaselocation" placeholder="Purchase Location" onChange={(event) => {setPurchaseLocation(event.target.value)}} />
                    <input className='m-1 p-2' type="text" id="purchasemileage" placeholder="Purchase Mileage" onChange={(event) => {setPurchaseMileage(event.target.value)}} />
                    <input className='m-1 p-2' type="text" id="purchaseamount" placeholder="Purchase Amount" onChange={(event) => {setPurchaseAmount(event.target.value)}} />
                    <input className='m-1 p-2' type="text" id="vID" value={vID} />
                    <input type='file' placeholder='select photo to upload' onChange={(event) => {setImageUpload(event.target.files[0])}} />
                    <hr />
                    <div className='d-flex justify-content-end'>
                        <a onClick={() => navigate("/vehicles")}><img className="icons" src={imgCancel} /></a>
                        {/* <a onClick={addVehicle}><img className="icons ms-3" src={imgSave} /></a>  */}
                        <a onClick={uploadImage}><img className="icons ms-3" src={imgSave} /></a> 
                    </div>
                    </form>
                </div> {/* end of add vehicle panel */}
        </div>
        
        </div>
    )
}

export default AddVehicle